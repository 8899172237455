<template>
    <div class="edit-profile-detail">
        <!-- <StackRouterHeaderBar left-button="close" title="가족관계" :showTitle="true" /> -->
        <div class="title" v-html="$translate(type.toUpperCase())" />
        <div class="section">
            <div class="section-title required">형제 구성</div>
            <div class="inputs">
                <TextareaWithX
                    v-model="brotherCount"
                    class="m-r-8 width-fix"
                    type="tel"
                    placeholder="숫자만 입력"
                    :isInputMode="true"
                />
                <span class="m-r-8">남</span>
                <TextareaWithX
                    v-model="sisterCount"
                    class="m-r-8 width-fix"
                    type="tel"
                    placeholder="숫자만 입력"
                    :isInputMode="true"
                />
                <span>녀</span>
            </div>
        </div>
        <div class="section">
            <div class="section-title required">본인 서열</div>
            <div class="inputs">
                <TextareaWithX
                    v-model="orderOfChildren"
                    class="m-r-8"
                    type="tel"
                    placeholder="숫자만 입력"
                    :isInputMode="true"
                />
                <span class="m-r-8">째</span>
            </div>
        </div>
        <!-- <BottomButton :disabled="disabled" @click="submit" :label="$translate('SAVE')" /> -->
    </div>
</template>

<script>
export default {
    name: 'SiblingRelation',
    // props: ['type'],
    props: {
        type: {
            type: String,
        },
        isEditing: {
            type: Boolean,
            default: false,
        },
        stage: {
            type: Number,
        },
    },
    data: () => ({
        brotherCount: null,
        sisterCount: null,
        orderOfChildren: null,
    }),
    computed: {
        profile() {
            return this.$store.getters.profile || {}
        },
        disabled() {
            // 0째는 존재할 수 없기 때문
            if (!Number(this.orderOfChildren)) return true

            return this.brotherCount === null || this.sisterCount === null
        },
    },
    watch: {
        disabled() {
            if (this.disabled) {
                this.$emit('disabled', true)
            } else {
                this.$emit('disabled', false)
            }
        },
    },
    methods: {
        onSave(key) {
            this.$set(this.profile, 'brother_count', Number(this.brotherCount))
            this.$set(this.profile, 'sister_count', Number(this.sisterCount))
            this.$set(this.profile, 'order_of_children', Number(this.orderOfChildren))

            // this.$stackRouter.pop()
        },
        init() {
            const { brother_count: bro, sister_count: sis, order_of_children: ooc } = this.profile
            if (bro && sis && ooc) {
                this.brotherCount = bro
                this.sisterCount = sis
                this.orderOfChildren = ooc
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
.inputs {
    display: flex;
    align-items: center;
}
.width-fix {
    width: 140px;
}
</style>
